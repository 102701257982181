import request from '@/utils/request'


// 查询logo列表
export function listLogo(query) {
  return request({
    url: '/door/door-logo/list',
    method: 'get',
    params: query
  })
}

// 查询logo分页
export function pageLogo(query) {
  return request({
    url: '/door/door-logo/page',
    method: 'get',
    params: query
  })
}

// 查询logo详细
export function getLogo(data) {
  return request({
    url: '/door/door-logo/detail',
    method: 'get',
    params: data
  })
}

// 新增logo
export function addLogo(data) {
  return request({
    url: '/door/door-logo/add',
    method: 'post',
    data: data
  })
}

// 修改logo
export function updateLogo(data) {
  return request({
    url: '/door/door-logo/edit',
    method: 'post',
    data: data
  })
}

// 删除logo
export function delLogo(data) {
  return request({
    url: '/door/door-logo/delete',
    method: 'post',
    data: data
  })
}
